import s from './Footer.module.scss';

const Footer = () => {
  return (
    <footer className={s.footer}>
      <a href='https://vk.com/adv_media_adv' className={s.item}>Наша группа ВК</a>
      <a href='https://vk.com/im?media=&sel=-224894545' className={s.item}>Написать нам</a>
    </footer>
  );
}

export default Footer